<template>
    <v-container>
        <v-breadcrumbs :items="breadcrumbs" link>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
        <v-card dark elevation="1">
            <v-card-title>
                แก้ไขข้อมูลผู้ใช้งาน
            </v-card-title>
            <v-card-subtitle>
                โปรดตรวจสอบความถูกต้องของข้อมูลก่อนยืนยัน
            </v-card-subtitle>
             <v-divider></v-divider>
             <v-card-text>
                <user-form :id="id" ref="userForm" />
                <v-divider></v-divider>
                    <v-btn class="mt-3" @click="$refs.userForm.submit()">
                        บันทึกรายการ
                    </v-btn>
             </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import UserForm from '../../components/UserForm.vue';

export default {
    data: () => ({
        id: null,
        breadcrumbs: [
        {
          text: 'หน้าหลัก',
          disabled: false,
          href: '/'
        },
        {
            text: 'ข้อมูลผู้ใช้งาน',
            disabled: false,
            href: '/users'
        },
        {
          text: 'แก้ไขข้อมูล',
          disabled: true,
          href: '/users/create'
        }
      ]
    }),
    mounted(){
        this.id = Number(this.$route.params.id) ? Number(this.$route.params.id) : null;
        this.breadcrumbs[1].href = '/users/' + this.$route.params.id;
    },
    components: {
        UserForm
    }
};
</script>